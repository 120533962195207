<template>
  <div class="pa-1 mt-6" v-if="!afterFinish">
    <div class="text-center mt-15 pa-5" v-if="!isStarted">
      Welcome to Primecom QA Game System. Are you ready to begin the game? If
      so, please click the "Start" button below. <br />
      <v-btn
        @click="
          isStarted = true;
          startTime = Date.now();
          score = 0;
        "
        style="background-color: green"
        text
        class="white--text text-capitalize mt-5"
      >
        Start
      </v-btn>
    </div>
    <div v-if="isStarted">
      <v-layout
        row
        wrap
        v-for="(question, index) in questionList"
        :key="index"
        class="ma-2"
      >
        <v-flex xs12 v-if="index == 0">
          <p class="headline mb-10 ml-3">Choose the best answer</p>
        </v-flex>
        <v-flex xs12>
          <span
            class="white--text pa-2 pl-5 pr-5 darken-3"
            style="background-color: #4f81bc"
            >Question {{ 1 + index }}
          </span>
          <span class="black--text"
            >&nbsp;&nbsp;&nbsp;&nbsp; {{ question.title }}</span
          >
        </v-flex>

        <v-flex xs7 md2 class="ml-1 mt-4">
          <v-img
            height="230px"
            width="335px"
            contain
            :src="require(`../assets/img/${question.imageURL}`)"
          >
          </v-img>
        </v-flex>

        <v-flex xs4 class="mt-6 ml-3">
          <span>
            <a
              v-for="(answer, i) in question.answers"
              :key="answer + i"
              @click="getSelectedAnswer(index + '-' + answer)"
            >
              <span
                v-if="selectedAnswers.indexOf(index + '-' + answer) == -1"
                class="pa-2 pt-1 pb-1 white--text"
                style="background-color: #4f81bc"
                >{{ choice[i] }}</span
              >

              <span
                v-else
                class="pa-2 pt-1 pb-1 white--text"
                style="background-color: red"
                >{{ choice[i] }}</span
              >

              <span class="pa-2 pt-1 ml-2 black--text">{{ answer }}</span>
              <br /><br />
            </a>
          </span>
        </v-flex>

        <v-flex
          xs12
          class="mb-1 text-center mt-10"
          v-if="index == questionList.length - 1"
        >
          <v-btn
            @click="submitResult"
            style="background-color: #2596be"
            text
            class="white--text text-capitalize"
          >
            Finish
          </v-btn>
        </v-flex>
        <v-flex xs12 class="mb-1" v-if="index != questionList.length - 1">
          <br /><br />
        </v-flex>
      </v-layout>
    </div>
  </div>
  <div v-else class="text-center mt-15">
    <p class="headline">Result</p>
    <span>Score: {{ score + "/" + questionList.length }}</span> <br />
    <span>Elapsed Time: {{ elapsedTime }}</span>
  </div>
</template>

<script>
export default {
  data: () => ({
    questionList: [
      // Assume this may be from database
      {
        title: "Solve the Fruit Puzzle",
        imageURL: "puzzle.jpg",
        answers: ["0", "500", "250", "1000", "375"],
        correctAnswer: "375",
      },
      {
        title: "Where is the Golden Gate Bridge?",
        imageURL: "goldenGateBridge.jpg",
        answers: ["Arizona", "Florida", "Texas", "California", "Georgia"],
        correctAnswer: "California",
      },

      //you can add more based on this format
    ],

    choice: ["A", "B", "C", "D", "E"],
    selectedAnswers: [],
    isStarted: false,
    startTime: 0,
    elapsedTime: "",
    score: 0,
    afterFinish: false,
  }),

  methods: {
    getSelectedAnswer(answer) {
      if (this.selectedAnswers.indexOf(answer) === -1) {
        var isFound = false;
        for (let i = 0; i < this.selectedAnswers.length; i++) {
          if (this.selectedAnswers[i].split("-")[0] == answer.split("-")[0]) {
            this.selectedAnswers.splice(i, 1, answer);
            isFound = true;
          }
        }
        if (!isFound) this.selectedAnswers.push(answer);
      }
    },

    submitResult() {
      if (this.selectedAnswers.length == this.questionList.length) {
        this.score = 0;
        this.elapsedTime = this.formatElapsedTime(Date.now() - this.startTime);
        for (let index = 0; index < this.questionList.length; index++) {
          for (let k = 0; k < this.selectedAnswers.length; k++) {
            if (
              this.questionList[index].correctAnswer ==
                this.selectedAnswers[k].split("-")[1] &&
              index.toString() == this.selectedAnswers[k].split("-")[0]
            )
              this.score++;
          }
        }
        this.afterFinish = true; // this must be updated to database or local storage to prevent trying again, if needed
      } else alert("Please ensure that you complete all of the questions.");
    },

    formatElapsedTime(elapsedinMilliseconds) {
      const microseconds = Math.floor((elapsedinMilliseconds % 1000) / 10);
      const totalSeconds = Math.floor(elapsedinMilliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      // Format minutes, seconds, and microseconds
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      const formattedMicroseconds = String(microseconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}:${formattedMicroseconds}`;
    },
  },
  created() {},
};
</script>
<style scoped></style>
