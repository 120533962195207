import Vue from "vue";
import VueRouter from "vue-router";

import questionAndAnswerGame from "@/components/questionAndAnswerGame";

Vue.use(VueRouter);
const routes = [
  {
    path: "/multiple-Choice-Question",
    name: "multipleChoiceQuestion",
    component: questionAndAnswerGame,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
