<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer
      style="border-top: 1px solid cadetblue; font-size: 12px"
      class="mt-10"
    >
      <v-col class="text-center">
        <span v-if="2024 == new Date().getFullYear()">
          &copy;{{ new Date().getFullYear() }} Primecom QA Game. All Rights
          Reserved</span
        >

        <span v-else>
          &copy;2024 - {{ new Date().getFullYear() }} Primecom QA Game. All
          Rights Reserved</span
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({
    //
  }),
};
</script>
<style scoped></style>
